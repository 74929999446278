var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "containerFlexColumn" },
    [
      _c(
        "el-row",
        { staticClass: "padding10", attrs: { gutter: 20 } },
        [
          _c("el-col", [
            _c(
              "div",
              {
                staticClass:
                  "bcWhite borderRadius5 border fs20 fWB paddingTop20 paddingLeft10 paddingBottom20",
              },
              [_vm._v("货品信息")]
            ),
          ]),
          _c("el-col", { attrs: { span: 20 } }, [
            _c(
              "div",
              {
                staticClass:
                  "bg-purple bcWhite border borderRadius5 marginTop10",
              },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c(
                        "div",
                        { staticClass: "bg-purple" },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "margin10",
                              model: {
                                value: _vm.tabs,
                                callback: function ($$v) {
                                  _vm.tabs = $$v
                                },
                                expression: "tabs",
                              },
                            },
                            [
                              _c("el-tab-pane", {
                                attrs: { label: "基本信息", name: "1" },
                              }),
                              _c("el-tab-pane", {
                                attrs: { label: "扩展信息", name: "2" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form",
                            {
                              ref: "form",
                              attrs: {
                                model: _vm.form,
                                rules: _vm.rules,
                                "label-width": "90px",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "货品名称",
                                            prop: "cname",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "635px" },
                                            model: {
                                              value: _vm.form.cname,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "cname", $$v)
                                              },
                                              expression: "form.cname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "货品分类",
                                            prop: "rsort",
                                          },
                                        },
                                        [
                                          _c("el-cascader", {
                                            staticClass: "custom-cas-goods",
                                            attrs: {
                                              value: _vm.form.rsort,
                                              options: _vm.treeData,
                                              props: {
                                                value: "sid",
                                                label: "ssort",
                                              },
                                              placeholder: "请选择",
                                            },
                                            on: {
                                              change: _vm.handleCascaderChange,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "货品SKU",
                                            prop: "csku",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.form.cbarcode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "cbarcode",
                                                  $$v
                                                )
                                              },
                                              expression: "form.cbarcode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "货品条码",
                                            prop: "cbarcode",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.form.csku,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "csku", $$v)
                                              },
                                              expression: "form.csku",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "品牌名称",
                                            prop: "rbrand",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                filterable: "",
                                                placeholder: "请选择品牌名称",
                                              },
                                              model: {
                                                value: _vm.form.rbrand,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "rbrand",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.rbrand",
                                              },
                                            },
                                            _vm._l(
                                              _vm.brandOption,
                                              function (dict) {
                                                return _c("el-option", {
                                                  key: dict.sid,
                                                  attrs: {
                                                    label: dict.cname,
                                                    value: dict.sid,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "计量单位",
                                            prop: "sunit",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择计量单位",
                                              },
                                              on: { change: _vm.unitSelect },
                                              model: {
                                                value: _vm.form.sunit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "sunit",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.sunit",
                                              },
                                            },
                                            _vm._l(
                                              _vm.unitOption,
                                              function (dict) {
                                                return _c("el-option", {
                                                  key: dict.dictLabel,
                                                  attrs: {
                                                    label: dict.dictLabel,
                                                    value: dict.dictValue,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "货品规格",
                                            prop: "cspecification",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "custom-select",
                                              attrs: {
                                                placeholder: "请选择货品规格",
                                              },
                                              model: {
                                                value: _vm.form.cspecification,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "cspecification",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.cspecification",
                                              },
                                            },
                                            _vm._l(
                                              _vm.specificationOption,
                                              function (dict) {
                                                return _c("el-option", {
                                                  key: dict.dictValue,
                                                  attrs: {
                                                    label: dict.dictLabel,
                                                    value: dict.dictValue,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "零售价",
                                            prop: "nprice",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.form.nprice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "nprice",
                                                  $$v
                                                )
                                              },
                                              expression: "form.nprice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "保质期限",
                                            prop: "nperiod",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择保质期限",
                                              },
                                              model: {
                                                value: _vm.form.nperiod,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "nperiod",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.nperiod",
                                              },
                                            },
                                            _vm._l(
                                              _vm.periodOption,
                                              function (dict) {
                                                return _c("el-option", {
                                                  key: dict.dictValue,
                                                  attrs: {
                                                    label: dict.dictLabel,
                                                    value: dict.dictValue,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-col",
                      { staticClass: "paddingTop20", attrs: { span: 6 } },
                      [
                        _vm.form.cmasterUrl === undefined
                          ? _c("el-empty", {
                              staticClass: "empty",
                              attrs: { description: "暂无数据" },
                            })
                          : _vm._e(),
                        _vm.form.cmasterUrl
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-purple-light padding20 paddingBottom20",
                                  staticStyle: { "margin-top": "30px" },
                                },
                                [
                                  _c("el-image", {
                                    key: _vm.urlIndex,
                                    staticStyle: { height: "230px" },
                                    attrs: {
                                      fit: "fit",
                                      src: _vm.form.cmasterUrl,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("el-switch", {
                                staticStyle: {
                                  "margin-left": "auto",
                                  "margin-right": "auto",
                                },
                                attrs: {
                                  "active-value": "0",
                                  "inactive-value": "1",
                                  "inactive-text": "启用状态",
                                },
                                model: {
                                  value: _vm.form.bvalid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bvalid", $$v)
                                  },
                                  expression: "form.bvalid",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-purple bcWhite border borderRadius5 marginTop10 padding10",
              },
              [
                _vm._v(" 货品标签 "),
                _c(
                  "div",
                  { staticClass: "marginTop10" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-new-tag",
                        attrs: { size: "small" },
                        on: { click: _vm.handleAddLabel },
                      },
                      [_vm._v("+ 添加标签")]
                    ),
                    _vm._l(_vm.dynamicTags, function (tag) {
                      return _c(
                        "el-tag",
                        {
                          key: tag.sid,
                          staticStyle: { "margin-left": "5px" },
                          attrs: { closable: "", "disable-transitions": false },
                          on: {
                            close: function ($event) {
                              return _vm.handleClose(tag)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(tag.cname) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-purple bcWhite border borderRadius5 marginTop10 padding10",
                staticStyle: { height: "262px" },
              },
              [
                _vm._v(" 货品详情 "),
                _c("quill-editor", {
                  staticClass: "marginTop10",
                  staticStyle: { height: "145px" },
                  attrs: { options: _vm.editorOption },
                  model: {
                    value: _vm.form.cdescribe,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cdescribe", $$v)
                    },
                    expression: "form.cdescribe",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _c(
              "div",
              {
                staticClass:
                  "bg-purple-light bcWhite border borderRadius5 marginTop10 scrollbarSet",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "paddingTop20 paddingBottom20 borderBottom textCenter",
                  },
                  [_vm._v("货品图片")]
                ),
                _c(
                  "el-scrollbar",
                  {
                    staticClass: "flexCenter",
                    staticStyle: { height: "670px" },
                  },
                  [
                    _c("image-upload", {
                      ref: "imageUpload",
                      staticClass: "w150 h150 marginBottom20 marginTop10",
                      on: { input: _vm.fileList },
                    }),
                    _vm._l(_vm.urls, function (url, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "marginBottom20",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w150 h150 padding10 border bcGrey marginBottom5",
                            },
                            [
                              _c("el-image", {
                                key: url,
                                staticClass: "hHP wHP",
                                attrs: { fit: "fit", src: url },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "onDelete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteImage(index)
                                    },
                                  },
                                },
                                [_vm._v("X")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "textCenter" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: url },
                                  model: {
                                    value: _vm.form.cmasterUrl,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "cmasterUrl", $$v)
                                    },
                                    expression: "form.cmasterUrl",
                                  },
                                },
                                [_vm._v("设为主图")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("el-col", [
            _c(
              "div",
              {
                staticClass:
                  "bcWhite border borderRadius5 marginTop10 padding10",
              },
              [
                _c(
                  "el-row",
                  { attrs: { type: "flex", justify: "end" } },
                  [
                    _c("el-button", { on: { click: _vm.cancel } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleSave },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "选择标签",
            visible: _vm.addDeviceOpen,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addDeviceOpen = $event
            },
          },
        },
        [
          _c("GoodsLabelSelect", {
            ref: "labelSelect",
            on: { "close-dialog": _vm.closeDialog },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }