<template>
  <div>
    <div class="flex h400">
      <div class="marginRight10 boxShadow scrollbar" style="width: 40%">
        <!--      <el-tree :props="props" :load="loadNode" lazy show-checkbox @check-change="handleCheckChange" />-->
        <el-tree ref="tree" show-checkbox class="filter-tree" :data="sortsData" :props="defaultProps" default-expand-all :filter-node-method="filterNode" @check-change="handleCheckChange" />
      </div>
      <div class="padding10 boxShadow" style="width: 60%">
        <el-input v-model="queryParams.cName" placeholder="选择标签" class="input-with-select wHP">
          <el-button slot="append" icon="el-icon-search" @click="handleQueryLabel"></el-button>
        </el-input>
        <el-scrollbar class="scrollbarSet marginTop10 h300">
          <el-tag v-for="tag in tags" :key="tag.sid" :class="{ active: isArrayObject(selectedTags, tag) }" class="marginTop10" :type="tag.type" @click="handleTag(tag)">
            {{ tag.cname }}
          </el-tag>
        </el-scrollbar>
      </div>
    </div>
    <el-row class="marginTop20 marginRight20" type="flex" justify="end">
      <el-button type="primary" plain @click="handleCancel">清除</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </el-row>
  </div>
</template>

<script>
import { labelList } from "@/api/goods/goodsLabel";
import { goodsSortList, handleTree } from "@/api/goods/goods";

export default {
  name: "GoodsLabelSelect",
  data() {
    return {
      input: "",
      props: {
        label: "name",
        children: "zones",
      },
      count: 1,
      tags: [],
      selectedTags: [], //已经选定的标签
      queryParams: {
        cName: "",
        sortIds: "",
      },
      //分类数据
      sortsData: [],
      defaultProps: {
        children: "children",
        label: "ssort",
      },
      //已经选定的分类id,选定后给查询传参
      selectIds: [],
    };
  },
  mounted() {
    this.handleQueryLabel();
    this.fetchGoodSorts();
  },
  methods: {
    //获取已选定的分类的所有分类id,供查询使用
    handleCheckChange(data, checked) {
      if (checked) {
        this.selectIds.push(data.sid);
      } else {
        const index = this.selectIds.indexOf(data.sid);
        if (index > -1) {
          this.selectIds.splice(index, 1);
        }
      }
      this.queryParams.sortIds = this.selectIds.join(",");
      this.handleQueryLabel();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.cname.indexOf(value) !== -1;
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: "region1" }, { name: "region2" }]);
      }
      if (node.level > 3) return resolve([]);

      var hasChild;
      if (node.data.name === "region1") {
        hasChild = true;
      } else if (node.data.name === "region2") {
        hasChild = false;
      } else {
        hasChild = Math.random() > 0.5;
      }

      setTimeout(() => {
        var data;
        if (hasChild) {
          data = [
            {
              name: "zone" + this.count++,
            },
            {
              name: "zone" + this.count++,
            },
          ];
        } else {
          data = [];
        }
        resolve(data);
      }, 500);
    },

    //获取标签查询
    handleQueryLabel() {
      labelList(this.queryParams).then((response) => {
        this.tags = response.data;
      });
    },

    //标签点击事件，添加到已选数组中，如果已选数组中包含标签，代表取消选定。
    handleTag(tag) {
      if (this.isArrayObject(this.selectedTags, tag)) {
        this.selectedTags = this.selectedTags.filter(function (value) {
          return JSON.stringify(value) !== JSON.stringify(tag);
        });
      } else {
        this.selectedTags.push(tag);
      }
    },

    //确认操作，关闭弹框，传递已选标签
    handleConfirm() {
      this.closeDialog(this.selectedTags);
    },

    //取消操作，清空已选择，并关闭弹框
    handleCancel() {
      this.selectedTags = [];
      this.closeDialog(this.selectedTags);
    },

    //关闭调用父组件弹框，并向调用组件传递已选标签
    closeDialog() {
      this.$emit("close-dialog", this.selectedTags);
    },

    //获取分类数据，组装成树结构
    fetchGoodSorts() {
      goodsSortList().then((response) => {
        this.sortsData = handleTree(response.data, "sid", "rsort");
      });
    },

    //判定数组中是否包含某个对象
    isArrayObject(arr, obj) {
      return arr.map((item) => JSON.stringify(item)).includes(JSON.stringify(obj));
    },

    //初始化标签的选择，需由父组件传递过来
    setInitTags(dynamicTags) {
      this.selectedTags = dynamicTags;
    },
  },
};
</script>

<style scoped>
.active {
  background-color: rgba(133, 232, 179, 0.78);
}
</style>
