import request from "../../utils/request";

/**品牌所有列表
 *
 * @param params
 * @returns {*}
 */
export function brandListAll(params) {
  return request({
    url: "/api/repository/repGoodsBrand/queryAll",
    method: "get",
    params: params,
  });
}

/**品牌列表
 *
 * @param params
 * @returns {*}
 */
export function brandList(params) {
  return request({
    url: "/api/repository/repGoodsBrand/queryAll",
    method: "get",
    params: params,
  });
}

/**根据id查询品牌
 *
 * @param sid
 * @returns {*}
 */
export function getBrandById(sid) {
  return request({
    url: "/api/repository/repGoodsBrand/get/" + sid,
    method: "get",
  });
}

/**添加品牌
 *
 * @param data
 * @returns {*}
 */
export function addBrand(data) {
  return request({
    url: "/api/repository/repGoodsBrand/add",
    method: "post",
    data: data,
  });
}

/**更新品牌
 *
 * @param data
 * @returns {*}
 */
export function updateBrand(data) {
  return request({
    url: "/api/repository/repGoodsBrand/update",
    method: "put",
    data: data,
  });
}

/**删除多个品牌
 *
 * @param sids
 * @returns {*}
 */
export function deleteBrands(sids) {
  return request({
    url: "/api/repository/repGoodsBrand/deletes/" + sids,
    method: "delete",
  });
}
