<template>
  <div class="containerFlexColumn">
    <el-row :gutter="20" class="padding10">
      <el-col>
        <div class="bcWhite borderRadius5 border fs20 fWB paddingTop20 paddingLeft10 paddingBottom20">货品信息</div>
      </el-col>
      <el-col :span="20">
        <div class="bg-purple bcWhite border borderRadius5 marginTop10">
          <el-row>
            <el-col :span="18">
              <div class="bg-purple">
                <el-tabs v-model="tabs" class="margin10">
                  <el-tab-pane label="基本信息" name="1" />
                  <el-tab-pane label="扩展信息" name="2" />
                </el-tabs>
                <el-form ref="form" :model="form" :rules="rules" label-width="90px">
                  <el-row>
                    <el-col>
                      <el-form-item label="货品名称" prop="cname">
                        <el-input v-model="form.cname" style="width: 635px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item label="货品分类" prop="rsort">
                        <el-cascader :value="form.rsort" :options="treeData" :props="{ value: 'sid', label: 'ssort' }" placeholder="请选择" class="custom-cas-goods" @change="handleCascaderChange"></el-cascader>
                      </el-form-item>
                      <!--                        <el-form-item label="所属分类" prop="rsort">-->
                      <!--                        <el-select ref="selectTree" v-model="value" class="custom-select">-->
                      <!--                          <el-option v-for="item in formatData(treeData)" :key="item.value" :label="item.label" :value="item.value" style="display: none" />-->
                      <!--                          <el-tree ref="selectTree" class="main-select-el-tree" :data="treeData" node-key="id" highlight-current :props="defaultProps" :current-node-key="value" :expand-on-click-node="expandOnClickNode" default-expand-all @node-click="handleNodeClick" />-->
                      <!--                        </el-select>-->
                      <!--                      </el-form-item>-->
                    </el-col>
                  </el-row>
                  <div>
                    <el-col :span="12">
                      <el-form-item label="货品SKU" prop="csku">
                        <el-input v-model="form.cbarcode"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="货品条码" prop="cbarcode">
                        <el-input v-model="form.csku"></el-input>
                      </el-form-item>
                    </el-col>
                  </div>
                  <div>
                    <el-col :span="12">
                      <el-form-item label="品牌名称" prop="rbrand">
                        <el-select v-model="form.rbrand" clearable filterable placeholder="请选择品牌名称">
                          <el-option v-for="dict in brandOption" :key="dict.sid" :label="dict.cname" :value="dict.sid"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="计量单位" prop="sunit">
                        <el-select v-model="form.sunit" placeholder="请选择计量单位" @change="unitSelect">
                          <el-option v-for="dict in unitOption" :key="dict.dictLabel" :label="dict.dictLabel" :value="dict.dictValue"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </div>
                  <div>
                    <el-col :span="24">
                      <el-form-item label="货品规格" prop="cspecification">
                        <el-select v-model="form.cspecification" placeholder="请选择货品规格" class="custom-select">
                          <el-option v-for="dict in specificationOption" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="零售价" prop="nprice">
                        <el-input v-model="form.nprice"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="保质期限" prop="nperiod">
                        <el-select v-model="form.nperiod" placeholder="请选择保质期限">
                          <el-option v-for="dict in periodOption" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </div>
                </el-form>
              </div>
            </el-col>
            <el-col :span="6" class="paddingTop20">
              <el-empty v-if="form.cmasterUrl === undefined" class="empty" description="暂无数据"></el-empty>
              <div v-if="form.cmasterUrl">
                <div class="bg-purple-light padding20 paddingBottom20" style="margin-top: 30px">
                  <el-image :key="urlIndex" style="height: 230px" fit="fit" :src="form.cmasterUrl" />
                </div>
              </div>
              <div>
                <div class="flex">
                  <el-switch v-model="form.bvalid" active-value="0" inactive-value="1" style="margin-left: auto; margin-right: auto" inactive-text="启用状态" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="bg-purple bcWhite border borderRadius5 marginTop10 padding10">
          货品标签
          <div class="marginTop10">
            <el-button class="button-new-tag" size="small" @click="handleAddLabel">+ 添加标签</el-button>
            <el-tag v-for="tag in dynamicTags" :key="tag.sid" closable :disable-transitions="false" style="margin-left: 5px" @close="handleClose(tag)">
              {{ tag.cname }}
            </el-tag>
          </div>
        </div>
        <div class="bg-purple bcWhite border borderRadius5 marginTop10 padding10" style="height: 262px">
          货品详情
          <!--          <quill-editor v-model="form.cdescribe" class="marginTop10" type="textarea"></quill-editor>-->
          <quill-editor v-model="form.cdescribe" class="marginTop10" :options="editorOption" style="height: 145px"></quill-editor>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="bg-purple-light bcWhite border borderRadius5 marginTop10 scrollbarSet">
          <div class="paddingTop20 paddingBottom20 borderBottom textCenter">货品图片</div>
          <el-scrollbar class="flexCenter" style="height: 670px">
            <image-upload ref="imageUpload" class="w150 h150 marginBottom20 marginTop10" @input="fileList"></image-upload>
            <div v-for="(url, index) in urls" :key="index" class="marginBottom20" style="position: relative">
              <div class="w150 h150 padding10 border bcGrey marginBottom5">
                <el-image :key="url" class="hHP wHP" fit="fit" :src="url" />
                <button class="onDelete" @click="deleteImage(index)">X</button>
              </div>
              <div class="textCenter">
                <el-radio v-model="form.cmasterUrl" :label="url">设为主图</el-radio>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </el-col>
      <el-col>
        <div class="bcWhite border borderRadius5 marginTop10 padding10">
          <el-row type="flex" justify="end">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="handleSave">保存</el-button>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="选择标签" :visible.sync="addDeviceOpen" width="40%" append-to-body>
      <GoodsLabelSelect ref="labelSelect" @close-dialog="closeDialog" />
    </el-dialog>
  </div>
</template>

<script>
import GoodsLabelSelect from "./goodsLabelSelect.vue";
import ImageUpload from "@/components/ImageUpload";
import { addGoods, editGoods, getFileUrls, getGoodsById, getLabelsById, goodsSortList, handleTree, loadGoodsDict, sortTreeSelect } from "@/api/goods/goods";
import { brandList, brandListAll } from "@/api/goods/goodsBrand";

export default {
  name: "GoodsAddInfo",
  components: { GoodsLabelSelect, ImageUpload },
  data() {
    return {
      sid: this.$route.query.sid,
      addDeviceOpen: false,
      dynamicTags: [],
      editorOption: {
        placeholder: "请输入货品详情...",
      },
      inputVisible: false,
      inputValue: "",
      tabs: "",
      radio: "",
      selectUnit: {},
      form: {},
      value1: "",
      urlIndex: "",
      urls: [],
      tempUrls: [],
      expandOnClickNode: true,
      value: "",
      treeData: [], //货品分类数据
      defaultProps: {
        children: "children",
        label: "label",
      },
      unitOption: [], //计量单位选项
      specificationOption: [], //货品规格选项
      periodOption: [], //保质期限选项
      brandOption: [], //品牌名称
      rules: {
        cname: [
          { required: true, message: "货品名称为必填项", trigger: "blur" },
          { max: 60, message: "长度需小于60个字符", trigger: "blur" },
        ],
        csku: [
          { required: true, message: "SKU为必填项", trigger: "blur" },
          { max: 50, message: "长度需小于50个字符", trigger: "blur" },
        ],
        rsort: [{ required: true, message: "货品分类为必选项", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getSortTree();
    this.getBrands();
    this.loadGoodsDict("dict_goods_unit");
    this.loadGoodsDict("dict_goods_specification");
    this.loadGoodsDict("dict_goods_period");
    if (this.sid) {
      this.fetchGoods(this.sid);
      this.getFileUrls();
      this.fetchTags(this.sid);
    }
  },
  mounted() {},
  methods: {
    deleteImage(index) {
      this.urls.splice(index, 1);
      this.$refs.imageUpload.deleteImage(index);
    },
    formatData(data) {
      let options = [];
      data.forEach((item, key) => {
        options.push({ label: item.label, value: item.id });
        if (item.children) {
          item.children.forEach((items, keys) => {
            options.push({ label: items.label, value: items.id });
            if (items.children) {
              items.children.forEach((itemss, keyss) => {
                options.push({ label: itemss.label, value: itemss.id });
                if (itemss.children) {
                  itemss.children.forEach((itemsss, keysss) => {
                    options.push({ label: itemsss.label, value: itemsss.id });
                  });
                }
              });
            }
          });
        }
      });
      return options;
    },
    //加载货品信息
    fetchGoods(sid) {
      getGoodsById(sid).then((response) => {
        this.form = response.data;
        this.value = this.form.rsort;
      });
    },
    //获取货品对应标签
    fetchTags(sid) {
      getLabelsById(sid).then((response) => {
        this.dynamicTags = response.data;
      });
    },
    unitSelect(row) {
      let s = this.unitOption.find((item) => item.dictValue === row);
      this.selectUnit.sunit = s.dictLabel;
      this.selectUnit.zunit = row;
    },
    handleNodeClick(node) {
      this.value = node.id;
      this.form.rsort = node.id;
      this.$refs.selectTree.blur();
    },
    handleChange(value) {
      // 处理下拉框值的变化
      this.selectedLabel = this.getLabelById(value);
    },
    handleCheckChange(data) {
      // 处理树形组件勾选状态的变化
      //...
    },
    getLabelById(id) {
      // 根据id获取label
      //...
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    //货品分类下拉级联数据
    getSortTree() {
      goodsSortList().then((response) => {
        this.treeData = handleTree(response.data, "sid", "rsort");
      });
    },
    //品牌名称
    getBrands() {
      brandList().then((response) => {
        this.brandOption = response.data;
      });
    },
    //关闭标签页所做操作
    closeDialog(selectedTags) {
      this.dynamicTags = [];
      for (let tag of selectedTags) {
        this.dynamicTags.push(tag);
      }
      this.addDeviceOpen = false;
    },
    //保存时表单数据带上标签，后台添加到关联表中
    handleSave() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.sunit = this.selectUnit.sunit;
          this.form.zunit = this.selectUnit.zunit;
          if (this.sid) {
            editGoods({ goods: this.form, labels: this.dynamicTags, urls: this.urls }).then((response) => {
              if (response.code === 200) {
                this.$message.success("保存成功");
              } else {
                this.$message.error(response.message);
              }
            });
          } else {
            addGoods({ goods: this.form, labels: this.dynamicTags, urls: this.urls }).then((response) => {
              this.$message.success("添加成功");
              this.$router.push({ path: "/goods/index" });
            });
          }
        }
      });
    },
    cancel() {
      this.$router.push({ path: "/goods/index" });
    },
    //加载字典数据
    loadGoodsDict(dictType) {
      loadGoodsDict(dictType).then((response) => {
        switch (dictType) {
          case "dict_goods_unit":
            this.unitOption = response.data;
            break;
          case "dict_goods_specification":
            this.specificationOption = response.data;
            break;
          case "dict_goods_period":
            this.periodOption = response.data;
        }
      });
    },

    //添加标签时，弹框，并初始化已选标签
    handleAddLabel() {
      this.addDeviceOpen = true;
      this.$nextTick(() => {
        this.$refs.labelSelect.setInitTags(this.dynamicTags);
      });
    },
    //图片追加方法，或图片添加方法，都放入this.urls
    fileList(files) {
      if ("" === files) {
        this.urls = [];
      } else {
        this.urls = this.tempUrls.concat(files.split(","));
      }
    },
    //加载单据已上传图片，初始this.urls
    getFileUrls() {
      getFileUrls(this.sid).then((response) => {
        this.tempUrls = response.data.map((item) => item.curl);
        this.urls = JSON.parse(JSON.stringify(this.tempUrls));
      });
    },
    validateDecimal(rule, value, callback) {
      if (value !== null && value !== undefined) {
        const reg = /^-?\d+(\.\d+)?$/;
        if (!reg.test(value)) {
          callback(new Error("请填写数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    handleCascaderChange(value) {
      this.form.rsort = value[value.length - 1];
    },
  },
};
</script>

<style lang="scss">
.el-scrollbar__wrap::-webkit-scrollbar {
  /* Webkit browsers */
  display: none;
}

.el-form-item {
  margin-bottom: 15px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.custom-cas-goods .el-input {
  width: 635px;
}
.onDelete {
  font-size: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
</style>
